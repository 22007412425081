import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__leftSide">
        <Link to="/">
          <img src="LAEEClogo.png" alt="laeec logo" />
        </Link>
        <div className="footer__name">
          LAEEC <br /> <a href="tel:225-303-1839">Phone: 225-303-1839</a> <br />{" "}
          <a href="mailto:info@laeec.com">Email: info@laeec.com</a>
        </div>
      </div>

      <div className="footer__rightSide">
        <div className="footer__links">
          <Link to="/about">
            <span className="footer__navButtons">About</span>
          </Link>
          <span className="line">|</span>
          <Link to="/services">
            <span className="footer__navButtons">Services</span>
          </Link>
          <span className="line">|</span>

          <Link to="/qualifications">
            <span className="footer__navButtons">Qualifications</span>
          </Link>
          <span className="line">|</span>

          <Link to="/location">
            <span className="footer__navButtons">Location</span>
          </Link>
          <span className="line">|</span>

          <Link to="/contact">
            <span className="footer__navButtons">Contact Us</span>
          </Link>
        </div>
        <span className="footer__copywright">
          © 2022 Louisiana Energy Equipment Co., L.L.C.
        </span>
      </div>
    </div>
  );
}

export default Footer;
