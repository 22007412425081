import React from "react";
import "./Location.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Location() {
  return (
    <div>
      <Header />
      <img src="cranes.png" alt="cranes" className="location__banner" />
      <div className="location__container">
        <span className="location__title">LOCATION</span>
        <span className="location__office">Office</span>
        <iframe
          className="location__mapOffice"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55044.06728670919!2d-91.05445830026568!3d30.428894912799493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8626bb7e5df3fd1f%3A0x1673eef29c768e56!2sBaton%20Rouge%2C%20LA%2070816!5e0!3m2!1sen!2sus!4v1659045337638!5m2!1sen!2sus"
          title="home office"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <a
          href="https://goo.gl/maps/qFsPpG2ByFKr8KuXA"
          target="_blank"
          rel="noreferrer"
        >
          <address className="location__office">
            5428 Stumberg Ln., <br /> Baton Rouge, LA 70816
          </address>
        </a>
        <div className="location__bottom">
          <img
            src="LAEECmap.png"
            alt="map of work done"
            className="location__mapNation"
          />
          <div className="location__bottomText">
            <span>
              Our personnel and their expertise provide services throughout the
              United States and South America.
            </span>
            <div>
              Effective management and a commitment to the development of our
              personnel are the cornerstones of our success. Our staff brings
              years of experience in dismantling, decommissioning and
              decontamination, and relocation of complex process systems and
              facilities.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Location;
