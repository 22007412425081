import React from "react";
import "./Contact.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9yf3i95",
        "template_wk4ffid",
        form.current,
        "mVlsDc0WkQbGKycG_"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Submitted");
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Header />
      <img src="roofs.png" alt="warehouses" className="contact__banner" />
      <div className="contact__container">
        <span className="contact__title">CONTACT US</span>
        <span className="contact__callToAction">
          We want to hear from you. Contact us!
        </span>
        <span className="contact__directions">
          Fill out the form below to send a message or tell us about your
          project
        </span>
        <form ref={form} onSubmit={sendEmail} className="contact__form">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            required
          />
          <input
            type="text"
            name="phone_number"
            placeholder="Phone Number"
            required
          />
          <input type="text" name="company" placeholder="Company" required />
          <input type="email" name="email" placeholder="Email" required />
          <textarea
            name="message"
            id="message"
            placeholder="How can we help?"
            required
          ></textarea>
          <input type="submit" value="SUBMIT" />
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
