import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
function Header() {
  return (
    <div className="header__container">
      <Link to="/">
        <div className="header__name">
          LOUISIANA <br /> ENERGY EQUIPMENT <br /> COMPANY
        </div>
      </Link>
      <Link to="/about">
        <span className="header__navButtons">About</span>
      </Link>
      <Link to="/services">
        <span className="header__navButtons">Services</span>
      </Link>
      <Link to="/qualifications">
        <span className="header__navButtons">Qualifications</span>
      </Link>
      <Link to="/location">
        <span className="header__navButtons">Location</span>
      </Link>
      <Link to="/contact">
        <span className="header__navButtons">Contact Us</span>
      </Link>
      <Link to="/">
        <img className="header__logo" src="LAEEClogo.png" alt="laeec logo" />
      </Link>
    </div>
  );
}

export default Header;
