import React from "react";
import "./Services.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Services() {
  return (
    <div>
      <Header />
      <img src="rig.png" alt="" className="services__banner" />
      <div className="services__container">
        <span className="services__title">SERVICES</span>
        <span className="services__info">
          Louisiana Energy Equipment’s vertically integrated organization allows
          us to offer a complete range of services for the remediation of
          industrial sites. We have the in-house resources to undertake complete
          remediation projects. We use a fully integrated management approach to
          project tasks, including site characterization, equipment removal,
          decontamination and decommissioning and site restoration.
        </span>
        <div className="serviceHighlight__container">
          <img className="service__pictures" src="demo6.png" alt="" />
          <div className="serviceHighlight__text">
            <span className="serviceHighlightText__title">
              Plant Dismantling and Decommissioning
            </span>
            <span className="serviceHighlightText__description">
              Plant dismantling and decommissioning are the historical core of
              our operation. They serve as a foundation for each of our other
              specialty services. The Personnel of Louisiana Energy Equipment
              has provided decommissioning service for many Fortune 500
              companies. The bulk of such services have been provided due to
              closures of chemical and manufacturing process plants. The scope
              and nature of dismantling services provided are carefully planned
              based on the chemical nature and contents of the subject facility.
            </span>
          </div>
        </div>
        <div className="serviceHighlight__container">
          <div className="serviceHighlight__text">
            <span className="serviceHighlightText__title">
              Plant Relocation
            </span>
            <span className="serviceHighlightText__description">
              The Plant Relocation Division of Louisiana Energy Equipment
              further displays the uniqueness of our company We provide a
              complete range of services necessary to relocate existing process
              plants to the desired locations. The relocation alternative
              represents significant cost and schedule savings over the
              procurement and construction of new process systems.
            </span>
          </div>
          <img className="service__pictures" src="demo2.png" alt="" />
        </div>
        <div id="service__lastDiv" className="serviceHighlight__container">
          <img className="service__pictures" src="demo1.png" alt="" />
          <div className="serviceHighlight__text">
            <span className="serviceHighlightText__title">Asset Recovery</span>
            <span className="serviceHighlightText__description">
              Louisiana Energy Equipment maintains an equipment department and
              in-house sales team to aggressively market acquired surplus
              equipment. In-depth market research and analysis are done on an
              ongoing basis. This assures The department constant awareness of
              the market demand for surplus equipment, types of equipment, and
              the potential users of equipment. A customized computer tracking
              system is in use to track surplus equipment inventories and sales.
            </span>
          </div>
        </div>
        <div className="clientList">
          <h3 className="clientList__heading">Partial Client List:</h3>
          <div className="clients">
            <ul>
              <li>Port Washington Power Plant Wisconsin</li>
              <li>Sabic Petrochemical Indiana</li>
              <li>Philips 66</li>
              <li>Southern Company</li>
              <li>USS Geneva Utah</li>
              <li>Exxon Baton Rouge</li>
              <li>Dow Chemical St. Charles</li>
              <li>Cabot</li>
              <li>Sunoco Marcus Hook, PA</li>
              <li>Gallo Glass</li>
            </ul>
            <ul>
              <li>Honeywell</li>
              <li>GE</li>
              <li>AirProducts</li>
              <li>Honda</li>
              <li>County Stadium Milwaukee</li>
              <li>Kingdome Seattle</li>
              {/* <li>Fur (check spelling)</li> */}
              <li>Cenco California Refinery</li>
              <li>DuPont Delaware</li>
              <li>Bristol Myers Squibb</li>
              <li>Dyno Nobel Wyoming</li>
            </ul>
            <ul>
              <li>Agrico Chemical Louisiana</li>
              <li>General Motors Linden NJ</li>
              <li>Texico Port Arthur, TX</li>
              <li>Shell Chemical Perth Amboy, NJ</li>
              <li>Sterling Chemical Texas City, TX</li>
              <li>Firestone Orange, TX</li>
              <li>Monsanto Luling, LA</li>
              <li>Nabisco Elizabeth, NJ</li>
              <li>Kerr-McGee Trona, California </li>
              <li>Consolidated Paper Detroit, MI</li>
              <li>Ford Edison, NJ</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
