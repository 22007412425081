import { Routes, Route } from "react-router-dom";
import "./App.css";
// import MainPage from "./MainPage";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Location from "./Pages/Location";
import Qualifications from "./Pages/Qualifications";
import Services from "./Pages/Services";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="location" element={<Location />}></Route>
        <Route path="qualifications" element={<Qualifications />}></Route>
        <Route path="services" element={<Services />}></Route>
      </Routes>
    </div>
  );
}

export default App;
