import React from "react";
import "./About.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function About() {
  return (
    <div>
      <Header />
      <img src="demo5.png" alt="demolition" className="about__banner" />
      <div className="about__container">
        <span className="about__title">ABOUT US</span>
        <div className="about__statement">
          Louisiana Energy Equipment Company has dedicated experts with 100 plus
          years of experience in the industry. <br />
          LAEEC, prioritizes the safety of our employees and our client’s
          satisfaction. We approach every project with public sensitivity to
          environmental issues by constantly monitoring regulations and offering
          flexible solutions.
        </div>
        <q className="about__quote">
          LAEEC aims to achieve our clients’ goals as safely and economically as
          possible while protecting the environment.
        </q>
        <span className="about__teamTitle">Our Team</span>
        {/* team contact diagram probably SVG */}
        <div>
          <object
            className="about__team"
            data="team.svg"
            type="image/svg+xml"
            aria-label="our team"
          ></object>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
