import React from "react";
import "./Qualifications.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Qualifications() {
  return (
    <div>
      <Header />
      <img
        src="qualificationsBanner.png"
        alt="demolition"
        className="qualifications__banner"
      />
      <div className="qualifications__container">
        <span className="qualifications__title">QUALIFICATIONS</span>
        <span className="qualifications__sectionTitle">
          Project Management Plan
        </span>
        <div className="qualifications__sectionDescription">
          At project initiation, Louisiana Energy Equipment will prepare a
          specific project management plan. The project management plan will
          include detailed project requirements for the abatement,
          decontamination and dismantling, and waste disposal efforts and will
          identify the following:
        </div>
        <div className="qualifications__listImgContainer">
          <ul>
            <li>
              Division of work for manageable elements (task) and work breakdown
              structure (WBS) for direct accountability.
            </li>
            <li>Interfaces within the work elements.</li>
            <li>
              Management methodology for successful integration of the work
              elements.
            </li>
            <li>
              Review process needed to ensure compliance with the project
              objectives.
            </li>
            <li>Staffing and expertise of personnel to perform the tasks.</li>
            <li>Project controls to provide control of cost and schedule.</li>
            <li>
              Criteria, requirements, and procedures for project functions.
            </li>
            <li>
              Reporting and analyses tools that provide status on cost and
              schedule; measured performance and estimates.
            </li>
            <li>Training requirements for the project personnel.</li>
            <li>Waste Management Plan.</li>
            <li>Procurement and Subcontracting Plan.</li>
          </ul>
          <img
            className="qualifications__bodyImg"
            src="qualifications__imgOne.png"
            alt=""
          />
        </div>
        <span className="qualifications__sectionTitle">
          Project Organization Approach
        </span>
        <div className="qualifications__sectionDescription">
          Our team approach proposes that the initial planning and procedures
          writing phase, and the actual work activities, be performed at the
          site. Having our project team and client personnel on site during the
          initial period proves to be cost effective and offers the advantages
          of effective on-site interfaces.
        </div>
        <span className="qualifications__sectionDescription">
          During the initial phase, the Project Team will:
        </span>
        <div className="qualifications__listImgContainer">
          <ul>
            <li>Set up the site office trailers</li>
            <li>
              Finalize plans for carrying out the Project Management Plan and
              controls
            </li>
            <li>Develop site specific Health and Safety Plan</li>
            <li>Commence building surveys</li>
            <li>Complete work plans • Establish task plans and sequences.</li>
            <li>Complete release criteria</li>
            <li>Compete for implementation</li>
            <div>
              <ul className="subUl">
                <li>Contamination control procedures.</li>
                <li>Asbestos work procedures</li>
                <li>
                  Waste packaging, certification, handling and storage
                  procedures for all waste types
                </li>
                <li>Decontamination procedures</li>
                <li>Worker safety procedures</li>
                <li>Characterization procedures</li>
                <li>Procurement procedures</li>
                <li>Warehousing and inventory programs</li>
              </ul>
            </div>
            <li>Begin the Project Control program</li>
            <li>Establish Subcontracting plan</li>
            <li>Mobilize craft labor</li>
          </ul>
          <img
            className="qualifications__bodyImg"
            src="qualifications__img2.png"
            alt=""
          />
        </div>

        <span className="qualifications__sectionTitle">
          Functions, Responsibilities and Authority of Supervisory Personnel
        </span>
        <span className="qualifications__subtitle">Project Manager</span>
        <ul>
          <li>
            Project plans, schedules, procedures, methods and techniques
            required for the technical and administrative performance of the
            project.
          </li>
          <li>
            Definition of the detailed Work Breakdown Structure for the project.
          </li>
          <li>
            Control and allocation of funds to meet contractual requirements.
          </li>
          <li>Project progress according to planned schedules.</li>
          <li>
            Promotion of safety as a priority among team members, including a
            formal program encourages personnel to openly express safety
            concerns.
          </li>
          <li>
            Timely submittal of technical and other administrative reports, as
            required by the contract.
          </li>
          <li>
            Technical accuracy, interface compatibility, and the construct
            ability and safety of the work and its proper application in the
            field.
          </li>
          <li>
            Maintenance of technical baseline documents and accompanying
            documents throughout all project phases.
          </li>
          <li>Document flow.</li>
          <li>Compliance with health and safety plans.</li>
          <li>Cost and schedule performance.</li>
        </ul>
        <span className="qualifications__subtitle">
          Project Administration Manager
        </span>
        <div className="qualifications__listImgContainer">
          <ul>
            <li>
              Establishing all project administrative procedures and assisting
              in their enforcement.
            </li>
            <li>
              Helping in cost estimates for all hardware, software, engineering,
              and construction services, and updating the estimates with
              responsible managers.
            </li>
            <li>
              Preparing a subcontracting plan and administering all
              subcontracts.
            </li>
            <li>Establishing and maintaining the detailed project schedule.</li>
            <li>Security administration.</li>
            <li>Personnel administration.</li>
            <li>Waste management.</li>
            <li>Procurement.</li>
          </ul>
          <img
            className="qualifications__bodyImg"
            src="qualifications__img3.png"
            alt=""
          />
        </div>
        <span className="qualifications__subtitle">
          Health and Safety Officer
        </span>
        <ul>
          <li>
            Verifying that safety and environmental design requirements are
            included in all project procedural documents.
          </li>
          <li>
            Developing the health and safety plan documenting how and why the
            health and safety design requirements have been adopted
          </li>
          <li>
            Reviewing the design for compliance with HSAP and advising the
            project manager of any necessary changes.
          </li>
          <li>Developing and carrying out all safety procedures.</li>
          <li>
            Monitoring all abatement and dismantling operations to assure all
            operations are according to environmental requirements.
          </li>
        </ul>
        <span className="qualifications__subtitle">
          Decontamination and Demolition Superintendent
        </span>
        <ul>
          <li>
            Review all project plans for adequacy of carrying out provisions.
          </li>
          <li>
            Lead all site related abatement, dismantlement and cleanup
            activities.
          </li>
          <li>
            Provide and manage all site services, including materials, heavy
            equipment, trailers, etc., required for the setting up phases of the
            project.
          </li>
          <li>
            Obtain required craft laborers from Louisiana Energy Equipment
            resources.
          </li>
          <li>
            Inform the project manager of any conflicts or issues, and work with
            the other functional group managers to resolve them.
          </li>
        </ul>
        <h3 className="tableName">Project Spotlight</h3>
        <table className="clientTable">
          <tr>
            <th>Client</th>
            <th>Project Specifics</th>
            <th>Contract Price</th>
          </tr>
          <tr>
            <th>Allied Chemical Baton Rouge, LA</th>
            <th>
              Contracted to remove the entire Soda Ash Plant, this was one of
              the largest projects at its time. It took 1.5 years to complete.
            </th>
            <th>Price $1,450.00 plus scrap of $1,000.00</th>
          </tr>
          <tr>
            <th>Texaco Port Natchez Texas</th>
            <th>
              Job consisted of all process equipment to be removed down to grade
              or concrete. All tanks, buildings, old piping and utilities,
              asbestos to be removed and disposed of. We had to work inside a
              live plant, project took 6 months.
            </th>
            <th>
              Sold a few compressors to coastal chemical refinery for
              $200,000.00
            </th>
          </tr>
          <tr>
            <th>Rohn and Haas Deerpark, TX</th>
            <th>
              Dismantled and salvaged a large paper coating and drying
              equipment. Was there over 6 months.
            </th>
            <th>$750,000.00</th>
          </tr>
          <tr>
            <th>Exxon Plastics Baton Rouge, LA</th>
            <th>
              Removed certain equipment (process) and relocated certain other
              equipment in the plant for Exxon's use.
            </th>
            <th>Contract price $300,000.00</th>
          </tr>
          <tr>
            <th>Rubicon Chemical</th>
            <th>
              Removed Acid plant from the site. Sold equipment and scrap. Sweep
              clean area when completed.
            </th>
            <th>Contract $450,000.00</th>
          </tr>
          <tr>
            <th>American Cyanamid Fort Worth, TX</th>
            <th>
              Removed entire plant which products hazardous chemicals and
              removed asbestos and lead. Project lasted 9 months.
            </th>
            <th>Approximately contract $1.200.000.00.</th>
          </tr>
          <tr>
            <th>Todd Shipyard Galveston, TX</th>
            <th>
              Dismantled all warehouses and pier, which extended 750 feet into
              the bay. Approximate time of 5 months.
            </th>
            <th>Price $750,000.00</th>
          </tr>
          <tr>
            <th>General Motors Linden, NJ</th>
            <th>
              Removed their power plant generators, boilers, stacks, concrete
              and debris. Project time of 6 months.
            </th>
            <th>Contract $800,000.00</th>
          </tr>
          <tr>
            <th>Acadian Fertilizer</th>
            <th>
              Dismantled, match marked, loaded, created and shipped to India for
              their re-erection. Time 1 year and 2 months.
            </th>
            <th>Cost $3.500.000.00</th>
          </tr>
          <tr>
            <th>Chevron Inc. lowa</th>
            <th>
              Dismantled nitric acid plant and a Urea plant. Shipped the entire
              plant to China for re-erection. Time 6 months.
            </th>
            <th>Price $1,500,000.00</th>
          </tr>
          <tr>
            <th>Brockton Edison Power Plant Brockton, Mass.</th>
            <th>
              Removed entire plant buildings, generators, boilers, and stacks
              for scrap, used dynamite to take down stacks. Time 6 months.
            </th>
            <th>Price $1,300,000.00</th>
          </tr>
          <tr>
            <th>Atlas Powder Company Dallas, TX</th>
            <th>
              Dismantled and rigged 100 ton absorber column in one piece, then
              loaded on railroad car and shipped to Joplin, Missouri where it
              was rigged off of R car and re-erected. All work was performed
              with a very restricted and limited area within critical operating
              facility.
            </th>
          </tr>
          <tr>
            <th>Becker Resources Marcus Hook, PA</th>
            <th>
              Dismantled ammonia plant located on Sun Oil property, was
              completely match marked including all large compressors and all
              buildings, with 250 ton converters. The entire plant was shipped
              to Carlsbad, New Mexico to be incorporated with the Cordoba Plant.
            </th>
          </tr>
          <tr>
            <th>Becker Resources Cordoba, IL</th>
            <th>
              Completely dismantled 300 ton per day Urea Plant. Everything from
              1 inch conduit to 90 foot 575,000 pound converter loaded onto
              trucks and rail, then shipped to Carlsbad, New Mexico. The
              equipment was unloaded in accordance with match marking procedures
              and then prepared for re- erection. Erection finalized in 1974.
            </th>
          </tr>
          <tr>
            <th>Becker Resources Carlsbad, NM</th>
            <th>
              Dismantled Ammonia Plant and Urea Plants to scrap and salvage a
              plant that I re-erected 25 years prior
            </th>
          </tr>
          <tr>
            <th>General Dynamics- Electric Boat Division Gorton, CT</th>
            <th>
              Erected a 200 ton prototype Liebherr Crane inside an enclosed
              building, many difficulties such as work space and drift. Erected
              the crane so that the Trident Submarine assisted to be built.
            </th>
          </tr>
          <tr>
            <th>General Dynamics- Quonset Point Kingston, RI</th>
            <th>
              Dismantled one of the largest plate benders in the world. A 5,000
              toon steel mill in Toronto, Canada- it was marked, crated and
              shipped to General Dynamics in Quonset Point. We then re-erected
              this unit including a 3,000 square foot of steel floor to support
              the rollers which move large plates through the unit.
            </th>
          </tr>
          <tr>
            <th>Gulf Oil Chemical Marietta, OH</th>
            <th>
              Completely dismantled and match marked an entire Polystyrene Unit
              located in New Jersey. The whole unit was loaded and shipped to
              Marietta, Ohio for reinstallation.
            </th>
          </tr>
          <tr>
            <th>Tenneco Linden, NJ</th>
            <th>
              Shipped a complete coffee processing plant, match marked and
              shipped to Mexico for re-erection.
            </th>
          </tr>
          <tr>
            <th>Louisiana Chemical and Marsco Engineering Baton Rouge, LA</th>
            <th>
              Completely dismantled and match marked Oxygen Plant including the
              cold box and all associated equipment and shipped to Tyler, TX for
              re-erection.
            </th>
          </tr>
        </table>
      </div>
      <Footer />
    </div>
  );
}

export default Qualifications;
