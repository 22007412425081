import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Home.css";
function Home() {
  return (
    <div>
      <Header />
      <img className="home__banner" src="homeBanner.png" alt="dismantling" />
      <div className="home__container">
        <div className="home__emoji">
          <img src="safety.png" alt="safety" className="emojis" />
          <img src="efficiency.png" alt="efficiency" className="emojis" />
          <img src="satisfaction.png" alt="satisfaction" className="emojis" />
        </div>
        <span className="home__quote">
          “Louisiana Energy Equipment Company aims to achieve our clients’ goals
          as safely and economically as possible while protecting the
          environment.”
        </span>
        <span className="main__hook">LAEEC WORKS NATION WIDE</span>
        <img src="LAEECmap.png" alt="service map" className="home__map" />
        <div className="home__infoBox">
          <div className="homeInfoBox__left">
            <span className="home__callToAction">
              Tell Us About Your Project!
            </span>
            <div className="home__promise">
              Louisiana Energy Equipment Company can provide our clients with
              improved cost and schedule performance, increased accountability,
              and reduced administrative burdens in a safe and timely manner.
            </div>
          </div>
          <div className="homeInfoBox__right">
            <div className="home__phoneNumber">
              <span>Phone</span>
              <a href="tel:2253031839">
                <span>225 303 1839</span>
              </a>
            </div>
            <div className="home__email">
              <span>Email</span>
              <a href="mailto:info@laeec.com">
                <span>info@laeec.com</span>
              </a>
            </div>
          </div>
        </div>
        <div className="home__bottomDesign"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
